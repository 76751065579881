<template>
  <v-container>
    <div>
      <span class="text-h4">Кейсы</span>
    </div>
    <v-card outlined class="mt-4 pa-6">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-img src="../assets/images/el.jpg" contain max-height="50vh" />
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7">
          <div>
            <span class="text-h4 deep-purple--text font-weight-bold"
              >EasyLife</span
            >
          </div>
          <div class="mt-2">
            <span class="body-text">
              <span class="deep-purple--text">Полный Справочник:</span> От
              магазинов до музеев - легко находите и исследуйте места.
              Возможность просмотра на карте и планирование маршрутов для
              удобного посещения магазинов, ресторанов и достопримечательностей.
            </span>
          </div>
          <div class="mt-2">
            <span class="body-text">
              <span class="deep-purple--text"
                >Платформа Частных Объявлений:</span
              >
              Опубликуйте объявление о продаже или найдите нужные вам товары на
              мультиязычном портале. Простой и удобный способ купли-продажи.
            </span>
          </div>
          <div class="mt-2">
            <span class="body-text">
              <span class="deep-purple--text"
                >Управление Жилыми Комплексами с EasyLife HOMES:</span
              >
              Эффективное управление жилищными вопросами. Оплачивайте
              коммунальные услуги, следите за деятельностью управляющей компании
              и активно участвуйте в жизни вашего жилищного комплекса
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined class="mt-4 pa-6">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-img
            src="../assets/images/raloyalty.jpg"
            contain
            max-height="50vh"
          />
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7">
          <div>
            <span class="text-h4 deep-purple--text font-weight-bold"
              >RaLoyalty</span
            >
          </div>
          <div class="mt-2">
            <span class="body-text">
              Сервис для организации промоакций в магазинах и сетях. Сервис
              предлагает инновационный подход к проведению промоакций в
              магазинах и торговых сетях. С данным инструментом вы легко сможете
              вносить изменения в условия акций, управлять расходом накопленных
              бонусов и просматривать информацию об участниках.
            </span>
          </div>
          <div class="mt-2">
            <span class="body-text">
              В данный момент участниками данного проекта являются около 100
              торговых точек.
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined class="mt-4 pa-6">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-img
            src="../assets/images/valstand.png"
            contain
            max-height="50vh"
          />
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7">
          <div>
            <span class="text-h4 deep-purple--text font-weight-bold"
              >Valstand</span
            >
          </div>
          <div class="mt-2">
            <span class="body-text">
              Сервис предоставляет удобную платформу для поиска и размещения
              объявлений о недвижимости. Пользователи могут легко просматривать
              актуальные предложения, фильтровать их по различным критериям и
              размещать свои объявления.
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined class="mt-4 pa-6">
      <v-row class="d-flex align-center">
        <v-col cols="12" md="5" lg="5" xl="5">
          <v-img
            src="../assets/images/matching.png"
            contain
            max-height="50vh"
          />
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7">
          <div>
            <span class="text-h4 deep-purple--text font-weight-bold"
              >Matching</span
            >
          </div>
          <div class="mt-2">
            <span class="body-text">
              Программное обеспечение для автоматического сопоставления
              наименований товаров из разных источников использует алгоритмы
              машинного обучения и обработки естественного языка для
              идентификации одинаковых товаров, несмотря на различия в их
              описаниях. Оно автоматизирует объединение данных и улучшает
              точность информации.
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "CasesComponent",
  data: () => ({}),
};
</script>
<style scoped>
.body-text {
  font-size: 16pt;
}
.body-subtitle-text {
  font-size: 20pt;
}
</style>


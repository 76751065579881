<template>
  <v-img src="../assets/images/header.jpg" height="93vh">
    <v-container>
      <v-row class="d-flex justify-center align-center text-center">
        <v-col cols="12" md="6" lg="6" xl="6">
          <div>
            <span
              class="text-body-2 text-md-h2 text-lg-h2 text-xl-h2 white--text font-weight-bold"
              >Byte World</span
            >
          </div>
          <div class="mt-4">
            <span
              class="text-caption text-md-h4 text-lg-h4 text-xl-h4 white--text font-weight-normal"
              >Индивидуальные решения для вашего бизнеса</span
            >
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <div>
            <v-img src="../assets/images/header-right.gif" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert
            border="left"
            colored-border
            color="deep-purple"
            elevation="2"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="10" lg="10" xl="10">
                <div>
                  <span class="text-body-2 font-weight-bold"
                    >Есть вопросы или нужны детали?
                  </span>
                </div>
                <div>
                  <span class="text-caption"
                    >Наша команда экспертов готова помочь вам найти оптимальные
                    решения для вашего бизнеса. Свяжитесь с нами, чтобы обсудить
                    ваши потребности и получить профессиональную
                    консультацию!</span
                  >
                </div>
              </v-col>
              <v-col cols="12" md="2" lg="2" xl="2" class="text-center">
                <div>
                  <v-btn
                    color="deep-purple"
                    outlined
                    :small="$vuetify.breakpoint.smAndDown"
                    >Оставить заявку</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>
<script>
export default {
  name: "HeaderComponent",
  data: () => ({}),
};
</script>
<style scoped>
.body-text {
  font-size: 16pt;
}
.body-subtitle-text {
  font-size: 20pt;
}
</style>
<template>
  <v-container>
    <div>
      <span class="text-h4">О нас</span>
    </div>
    <v-alert
      border="bottom"
      colored-border
      color="deep-purple"
      outlined
      shaped
      class="mt-4"
    >
      <div>
        <span class="body-text">
          Мы -
          <span class="font-weight-bold deep-purple--text">Byte World</span>,
          компания в области разработки программного обеспечения. С 2020 года мы
          специализируемся на создании инновационных IT-решений для различных
          отраслей.
        </span>
      </div>
      <div>
        <span class="body-text">
          Наша миссия - помогать бизнесам достигать своих целей через передовые
          технологии и высокий уровень сервиса.
        </span>
      </div>
    </v-alert>

    <v-alert border="bottom" colored-border color="grey" outlined shaped>
      <div>
        <span class="body-text">
          Мы стремимся к высочайшему качеству и индивидуальному подходу к
          каждому проекту. Наша команда состоит из опытных специалистов, готовых
          применить свой профессионализм и творческий подход для решения самых
          сложных задач.
        </span>
      </div>
    </v-alert>

    <v-alert border="bottom" colored-border color="green" outlined shaped>
      <div>
        <span class="body-text">
          Мы гордимся нашими партнерами и достигнутыми результатами в различных
          проектах. Наш подход основан на открытости, надежности и постоянном
          стремлении к инновациям.
        </span>
      </div>
      <div>
        <span class="body-text">
          Выбирая
          <span class="font-weight-bold green--text">Byte World</span>, вы
          выбираете надежного партнера для вашего бизнеса.
        </span>
      </div>
    </v-alert>
  </v-container>
</template>
<script>
export default {
  name: "AboutUsComponent",
  data: () => ({}),
};
</script>
<style scoped>
.body-text {
  font-size: 16pt;
}
.body-subtitle-text {
  font-size: 20pt;
}
</style>
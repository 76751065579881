<template>
  <v-container>
    <div>
      <span class="text-h4">Услуги</span>
    </div>
    <div class="mt-4">
      <v-row>
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-card height="100%" outlined>
            <div class="pa-6 deep-purple d-flex align-center">
              <v-icon color="white">mdi-cog</v-icon>
              <span class="white--text text-h6 mx-2">Разработка ПО</span>
            </div>
            <div class="pa-6 body-text">
              Наша команда специализируется на разработке индивидуальных
              программных решений, адаптированных к вашим уникальным
              потребностям. Мы создаем мобильные и веб-приложения, которые не
              только функциональны, но и эстетически привлекательны.
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-card height="100%" outlined>
            <div class="pa-6 grey d-flex align-center">
              <v-icon color="white">mdi-face-agent</v-icon>
              <div class="white--text text-h6 mx-2">Интеграция и поддержка</div>
            </div>
            <div class="pa-6 body-text">
              Мы предлагаем комплексные решения по интеграции новых систем с
              существующими, а также поддержку и сопровождение ваших
              IT-инфраструктур. Наша цель - обеспечить бесперебойную работу
              ваших приложений и систем.
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-card height="100%" outlined>
            <div class="pa-6 green d-flex align-center">
              <v-icon color="white">mdi-office-building-cog-outline</v-icon>
              <div class="white--text text-h6 mx-2">Консультации и аудит</div>
            </div>
            <div class="pa-6 body-text">
              Мы предлагаем консультации по вопросам IT-стратегий, а также
              проводим аудиты существующих систем, чтобы помочь вам
              оптимизировать процессы и повысить эффективность вашего бизнеса.
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "ServicesComponent",
  data: () => ({}),
};
</script>
<style scoped>
.body-text {
  font-size: 16pt;
}
.body-subtitle-text {
  font-size: 20pt;
}
</style>
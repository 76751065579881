<template>
  <v-footer color="deep-purple" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="(item, index) in menuItems"
        :key="index"
        color="white"
        text
        rounded
        class="my-2 text-capitalize"
      >
        {{ item.title }}
      </v-btn>
      <v-col
        class="deep-purple lighten-1 py-4 text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Byte World</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "FooterComponent",
  data: () => ({
    menuItems: [
      {
        title: "Главная",
        link: "#home",
      },
      {
        title: "Отзывы",
        link: "#reviews",
      },
      {
        title: "О нас",
        link: "#about",
      },
    ],
  }),
};
</script>
<style scoped>
.body-text {
  font-size: 16pt;
}
.body-subtitle-text {
  font-size: 20pt;
}

<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            color="purple"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" elevation="0">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center justify-center">
        <span class="black--text">Byte World</span>
      </div>
      <v-spacer />
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          v-for="(item, index) in menuItems"
          :key="index"
          text
          class="font-weight-regular text-body-2 text-capitalize"
        >
          {{ item.title }}
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <HeaderComponent />
      <AboutUsComponent />
      <ServicesComponent />
      <CasesComponent />
    </v-main>
    <FooterComponent />
  </v-app>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import AboutUsComponent from "./components/AbousUsComponent.vue";
import ServicesComponent from "./components/ServicesComponent.vue";
import CasesComponent from "./components/CasesComponent.vue";
export default {
  name: "App",

  components: {
    HeaderComponent,
    AboutUsComponent,
    ServicesComponent,
    FooterComponent,
    CasesComponent,
  },

  data: () => ({
    drawer: false,
    menuItems: [
      {
        title: "Главная",
        link: "#home",
      },
      {
        title: "Отзывы",
        link: "#reviews",
      },
      {
        title: "О нас",
        link: "#about",
      },
    ],
  }),
};
</script>

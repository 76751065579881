import { render, staticRenderFns } from "./HeaderComponent.vue?vue&type=template&id=82e807dc&scoped=true"
import script from "./HeaderComponent.vue?vue&type=script&lang=js"
export * from "./HeaderComponent.vue?vue&type=script&lang=js"
import style0 from "./HeaderComponent.vue?vue&type=style&index=0&id=82e807dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82e807dc",
  null
  
)

export default component.exports